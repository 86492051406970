<template>
	<v-container style="background: #1e1e1e; min-height: 100vh" fluid>
		<v-app-bar prominent app :short="true" elevation="1" id="hdr" :dark="true">
			<v-row dense>
				<v-col>
					<v-row dense>
						<v-col>
							<h2 style="color: white; text-align: center">Scroll Settings</h2>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col>
							<h5 style="color: white; text-align: center">
								Settings for scrolling through scores
							</h5>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-app-bar>
		<v-list flat dark>
			<v-list-item-group>
				<v-list-item
					v-for="(item, idx) in items"
					:key="`${item.Title}-${item.visible}`"
					:class="SelectedItem == idx ? 'highlighted' : ''"
					@mouseover="SelectedItem = idx"
					@click="select(item)"
					:to="item.to"
					v-show="item.visible"
				>
					<v-list-item-avatar :color="SelectedItem == idx ? 'primary' : ''">
						<v-icon v-show="SelectedItem == idx" style="color: white">
							mdi-target
						</v-icon>
						<v-icon v-show="SelectedItem != idx" style="color: white">
							{{ item.icon }}
						</v-icon>
					</v-list-item-avatar>
					<v-list-item-content>
						<h2 class="my-1 mb-2">
							{{ item.Title }}
						</h2>
					</v-list-item-content>
					<v-list-item-icon>
						<v-icon v-if="item.SettingTitle == null">
							{{ item.rightIcon() }}
						</v-icon>
						<span v-else>
							<v-row align="center">
								<v-col>
									{{ item.SettingTitle() }}
								</v-col>
							</v-row>
						</span>
					</v-list-item-icon>
				</v-list-item>
			</v-list-item-group>
		</v-list>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			SelectedItem: 0,
			SelectedSpeed: 0,
			SelectedScores: 0,
			speeds: [10 * 1000, 15 * 1000, 30 * 1000, 45 * 1000, 60 * 1000],
			limitscores: [
				-1, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
			],
			items: [
				{
					Title: 'Back',
					subtitle: null,
					icon: 'mdi-undo',
					visible: true,
					action: () => {
						this.$router.go(-1);
					},
					SettingTitle: () => {
						return '';
					},
				},
				{
					Title: 'Scroll Speed',
					subtitle: '',
					visible: true,
					action: () => {
						this.SelectedSpeed++;
						if (this.SelectedSpeed >= this.speeds.length)
							this.SelectedSpeed = 0;
						this.$store.dispatch(
							'set_scrollspeed',
							this.speeds[this.SelectedSpeed]
						);
					},
					SettingTitle: () => {
						this.SelectedSpeed;
						return this.$store.state.ScrollTime == -1
							? 'Disabled'
							: `Scroll Every ${this.$store.state.ScrollTime / 1000} seconds`;
					},
				},
				{
					Title: 'Enable Now Showing',
					SettingTitle: null,
					subtitle: '',
					visible: true,
					rightIcon: () => {
						return this.$store.state.NowShowing
							? 'mdi-checkbox-marked'
							: 'mdi-checkbox-blank-outline';
					},
					action: () => {
						this.$store.dispatch(
							'set_nowshowing',
							!this.$store.state.NowShowing
						);
					},
				},
				{
					Title: 'Enable Scroll Down',
					SettingTitle: null,
					subtitle: '',
					visible: true,
					rightIcon: () => {
						return this.$store.state.EnableScrollDown
							? 'mdi-checkbox-marked'
							: 'mdi-checkbox-blank-outline';
					},
					action: () => {
						this.$store.dispatch(
							'set_scrolldown',
							!this.$store.state.EnableScrollDown
						);
						let x = this.items[4];
						x.visible = this.$store.state.EnableScrollDown;
						this.$set(this.items, 4, x);
					},
				},
				{
					Title: 'Limit Scores',
					subtitle: '',
					visible: this.$store.state.EnableScrollDown,
					action: () => {
						this.SelectedScores++;
						if (this.SelectedScores >= this.limitscores.length)
							this.SelectedScores = 0;
						this.$store.dispatch(
							'set_limit',
							this.limitscores[this.SelectedScores]
						);
					},
					SettingTitle: () => {
						this.SelectedScores;
						return this.$store.state.LimitTopScores == -1
							? 'Disabled'
							: `Only show the top ${this.$store.state.LimitTopScores} scores`;
					},
				},
			],
		};
	},
	mounted() {
		window.addEventListener('keydown', this.keyboardShortcuts);
		this.SelectedSpeed = this.speeds.findIndex(
			(x) => x == this.$store.state.ScrollTime
		);
		this.SelectedScores = this.limitscores.findIndex(
			(x) => x == this.$store.state.LimitTopScores
		);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.keyboardShortcuts);
	},
	methods: {
		select(item) {
			item.action();
		},
		/**
		 * Should be +1 or -1
		 */
		move(dir) {
			if (dir == 1) {
				if (this.SelectedItem == this.items.length - 1) this.SelectedItem = -1;
				this.SelectedItem++;
			} else {
				if (this.SelectedItem == 0) this.SelectedItem = this.items.length;
				this.SelectedItem--;
			}
			if (!this.items[this.SelectedItem].visible) this.move(dir);
		},
		keyboardShortcuts(key) {
			if (window.enable_debug.keystrokes) console.log(key.key, key.keyCode);
			switch (key.key) {
				case 'ArrowLeft':
				case 'ArrowUp':
					this.move(-1);
					break;
				case 'ArrowRight':
				case 'ArrowDown':
					this.move(1);
					break;
				case 'Enter':
					this.select(this.items[this.SelectedItem]);
					break;
				case 'Escape':
					this.$router.push('/settings');
					break;
			}
		},
	},
};
</script>

<style scoped>
.highlighted {
	background: #24598c;
}
</style>
